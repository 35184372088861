import React from "react";
import { Container, Row, Image, Col } from "react-bootstrap";
import styles from './footer.module.css';

function Footer() {
    return (
        <footer className={styles.foot} role="contentinfo">
            <Container fluid className={styles.footer}>
                <Row className="p-4">
                    <Col className="d-flex justify-content-center align-items-center">
                        <Image
                            src="/images/cesLogo.svg"
                            alt="CES Logo"
                            width={250}
                            className={styles.logo}
                        />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center">
                        <h4 id="footer-info">Serving the Gulf Coast since: 1981</h4>
                    </Col>
                    <Col>
                        <div className={styles.locations} role="region" aria-labelledby="locations">
                            <h4 id="locations">Locations</h4>
                            <Row className="justify-content-center">
                                <Col md="auto">
                                    <div>
                                        <a 
                                            href="https://www.google.com/maps/place/CES+Team+One+Communications,+Inc./@30.4481484,-87.2360182,16z/data=!4m16!1m9!3m8!1s0x8890bf773d996291:0x813a29b0467088ea!2sCES+Team+One+Communications,+Inc.!8m2!3d30.4481438!4d-87.2334433!9m1!1b1!16s%2Fg%2F11b75l1s9b!3m5!1s0x8890bf773d996291:0x813a29b0467088ea!8m2!3d30.4481438!4d-87.2334433!16s%2Fg%2F11b75l1s9b?entry=ttu"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.locationLinks}
                                            aria-label="Directions to Pensacola, FL location: 215 W Herman St"
                                        >
                                            <h5>Pensacola, FL</h5>
                                            <p>215 W Herman St</p>
                                        </a>
                                    </div>
                                </Col>
                                <Col md="auto">
                                    <div>
                                        <a 
                                            href="https://www.google.com/maps/place/Team+One+Communications/@30.665023,-88.129903,15z/data=!4m16!1m9!3m8!1s0x889a4d081101d3a9:0x369479f0036b9721!2sTeam+One+Communications!8m2!3d30.665023!4d-88.129903!9m1!1b1!16s%2Fg%2F1txfpyfn!3m5!1s0x889a4d081101d3a9:0x369479f0036b9721!8m2!3d30.665023!4d-88.129903!16s%2Fg%2F1txfpyfn?entry=ttu"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.locationLinks}
                                            aria-label="Directions to Mobile, AL location: 3360 Key St"
                                        >
                                            <h5>Mobile, AL</h5>
                                            <p>3360 Key St</p>
                                        </a>
                                    </div>
                                </Col>
                                <Col md="auto">
                                    <div>
                                        <a 
                                            href="https://maps.app.goo.gl/SN7od9P7pRUi6r7P9"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.locationLinks}
                                            aria-label="Directions to Pascagoula, MS location: 1777 Old Mobile Ave"
                                        >
                                            <h5>Pascagoula, MS</h5>
                                            <p>1810 Old Mobile Ave</p>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <div className={styles.contact} role="region" aria-labelledby="contact-us">
                            <h4 id="contact-us">Contact Us</h4>
                            <div>
                                <a 
                                    href="tel:+18504327787"
                                    className={styles.contactSub}
                                    aria-label="Call Pensacola, FL office: (850) 432-7787"
                                >
                                    Pensacola, FL (850) 432-7787
                                </a>
                            </div>
                            <div>
                                <a 
                                    href="tel:+12513432560"
                                    className={styles.contactSub}
                                    aria-label="Call Mobile, AL office: (251) 343-2560"
                                >
                                    Mobile, AL (251) 343-2560
                                </a>
                            </div>
                            <div>
                                <a 
                                    href="tel:+12287623721"
                                    className={styles.contactSub}
                                    aria-label="Call Pascagoula, MS office: (228) 762-3721"
                                >
                                    Pascagoula, MS (228) 762-3721
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
